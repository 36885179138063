.homepage__title {
  text-indent: -9999px;
  display: none;
}

@font-face {
  font-family: Roboto;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.eot"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.woff") format("woff"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.ttf") format("truetype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.svg#robotobold") format("svg");
}

.tempPaidVideo {
  margin: 10px 0;
}

.operator a {
  color: #99a4aa;
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
  text-decoration: none;
}

.operator a:hover {
  color: #99a4aa;
  text-decoration: underline;
}

.login-container {
  float: right;
  color: #7b858b;
  padding: 28px 10px 0 0;
  position: relative;
}

.login-container:after, .login-container:before {
  content: "";
  clear: both;
  display: table;
}

.login-container .user-mail {
  margin: 0 10px 0 0;
  font-size: 12px;
  position: relative;
  top: 6px;
}

.login-container .login-modal, .login-container .login-modal-opener {
  float: right;
}

.login-container .dataimg-circle-head {
  width: 32px;
  height: 32px;
  display: block;
}

.login-container .dataimg-circle-head svg path {
  fill: #7b858b;
}

.share-strip {
  clear: both;
  flex-flow: row;
  justify-content: space-between;
  width: 220px;
  height: 36px;
  display: flex;
}

.share-strip > .share .icone {
  background-image: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
}

.share-strip > .share .icone.dataimg-facebook-white-2 {
  background: #4e6fdc;
}

.share-strip > .share .icone.dataimg-facebook-white-2 > svg {
  width: 20px;
  height: 20px;
  position: relative;
  top: 8px;
  left: 8px;
}

.share-strip > .share .icone.dataimg-facebook-white-2 > svg path {
  fill: #fff;
}

.share-strip > .share .icone.dataimg-twitter-white {
  background-color: #34aae6;
}

.share-strip > .share .icone.dataimg-twitter-white > svg {
  width: 20px;
  height: 20px;
  position: relative;
  top: 8px;
  left: 8px;
}

.share-strip > .share .icone.dataimg-twitter-white > svg path {
  fill: #fff;
}

.share-strip > .share .icone.dataimg-messenger {
  background-color: #2196f3;
}

.share-strip > .share .icone.dataimg-messenger > svg {
  width: 20px;
  height: 20px;
  position: relative;
  top: 8px;
  left: 8px;
}

.share-strip > .share .icone.dataimg-messenger > svg path {
  fill: #fff;
}

.share-strip > .share .icone.dataimg-whatsapp {
  background-color: #0ec965;
}

.share-strip > .share .icone.dataimg-whatsapp > svg {
  width: 20px;
  height: 20px;
  position: relative;
  top: 8px;
  left: 8px;
}

.share-strip > .share .icone.dataimg-whatsapp > svg path {
  fill: #fff;
}

.share-strip > .share .icone.dataimg-email-icon {
  background-color: #e80d41;
}

.share-strip > .share .icone.dataimg-email-icon > svg {
  width: 20px;
  height: 20px;
  position: relative;
  top: 8px;
  left: 8px;
}

.share-strip > .share .icone.dataimg-email-icon > svg path {
  fill: #fff;
}

#breadcrumbs {
  align-items: center;
  display: flex;
}

#breadcrumbs h1 {
  color: #5b6770;
  font-size: 12px;
  line-height: normal;
}

.premium-sticker {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #cca550;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  font-family: roboto, arial, sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  text-decoration: none;
  display: flex;
}

.premium-sticker:hover, .premium-sticker:focus {
  color: #fff;
  text-decoration: none;
}

.premium-sticker--inline {
  vertical-align: text-bottom;
  margin-right: 10px;
  display: inline-block;
}

.premium-sticker--small {
  padding-top: 2px;
  font-size: 11px;
  line-height: 12px;
}

.premium-sticker--small-mobile {
  line-height: 21px;
}

.premium-sticker--videoplayer-big {
  padding-left: 10px;
  padding-right: 10px;
  line-height: 38px;
}

.list-article-normal .premium-sticker {
  margin-right: 5px;
}

@media (max-width: 760px) {
  .related-articles-list .premium-sticker {
    position: absolute;
    top: 0;
    left: 20px;
  }
}

.category-flags--big .premium-sticker {
  padding-left: 10px;
  padding-right: 10px;
}

#gdpr-form textarea {
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  border: 1px solid #a9a9a9;
  width: 100%;
  padding: 2px 5px;
}

#gdpr-form .gdpr-form__btns-wrapper {
  text-align: left;
}

#gdpr-form .gdpr-form__btns-wrapper .login-button {
  text-align: center;
  vertical-align: unset;
  min-width: 93px;
  margin-right: 10px;
  padding: 0;
  display: inline-block;
}

#navigation ul li {
  width: auto;
  min-width: 115px;
}

#navigation ul li a.menuItem {
  width: auto;
  padding: 0 15px;
  font-size: 15px;
}

.innerContainer .subMenu {
  text-align: center;
}

.innerContainer .subMenu > li {
  margin: 0 15px 0 0 !important;
}

.container .innerContainer.greyContainer.topShadow[data-menu-id] {
  display: none;
}

.cnc-menu nav > ul > li > a {
  padding-left: 15px !important;
}

.cnc-menu nav > ul > li:first-child > a {
  padding-left: 0 !important;
}

